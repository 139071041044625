<div class="hero header-overlay" [style.background]="overlayBackground">
    <div class="background-image" [style.background-image]="'url('+ content.headerImage +')'" [style.opacity]="content.headerImageOpacity"></div>
    <svg viewBox="0 0 100 15" style="position: absolute;bottom: 0;left: 0;z-index: 2;">
        <path fill="#fff" opacity="0.1" d="M0 30 V15 Q30 3 60 15 V30z"></path>
        <path class="wave-fill" d="M0 30 V12 Q30 17 55 12 T100 11 V30z"></path>
    </svg>
    <div class="hero-content">
        <material-navbar [transparent]="true" [container]="true" class="login-button-white" [menuPosition]="settings.get('vebto.navbar.defaultPosition')"></material-navbar>
        <div class="be-container">
            <h1 class="header-title">{{content.headerTitle}}</h1>
            <p class="header-description">{{content.headerSubtitle}}</p>
            <div class="action-buttons">
                <a class="get-started-button" mat-raised-button color="accent" *ngIf="content?.actions?.cta1" routerLink="/login">{{content.actions.cta1}}</a>
                <button class="learn-more-button" mat-button (click)="scrollToFeatures()" *ngIf="content?.actions?.cta2">{{content.actions.cta2}}</button>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="content.primaryFeatures.length">
    <div class="be-container">
        <ad-host slot="ads.landing.top"></ad-host>
        <ul class="inline-features">
            <li class="inline-feature" *ngFor="let primaryFeature of content.primaryFeatures">
                <img [src]="primaryFeature.image" alt="">
                <h3>{{primaryFeature.title}}</h3>
                <p>{{primaryFeature.subtitle}}</p>
            </li>
        </ul>
    </div>

    <div class="spacer"></div>
</ng-container>

<ul class="big-features">
    <ng-container *ngFor="let secondaryFeature of content.secondaryFeatures; first as first; last as last; even as even">
        <li class="big-feature be-container"  [class.first-secondary-feature]="first" [class.reverse]="even">
            <img [src]="secondaryFeature.image + '?v2'" alt="">
            <section class="info">
                <small>{{secondaryFeature.subtitle}}</small>
                <h2>{{secondaryFeature.title}}</h2>
                <div class="feature-border"></div>
                <p>{{secondaryFeature.description}}</p>
            </section>
        </li>

        <div class="spacer" *ngIf="!last"></div>
    </ng-container>
</ul>

<div class="spacer"></div>

<div class="bottom-cta" [style.background-image]="'url('+ content.footerImage +')'">
    <div class="be-container">
        <h2 class="footer-title">{{content.footerTitle}}</h2>
        <p class="footer-description">{{content.footerSubtitle}}</p>
        <a mat-stroked-button routerLink="/login" *ngIf="content.actions?.cta1">{{content.actions.cta1}}</a>
    </div>
</div>

<footer>
    <div class="be-container">
        <div class="meta">
            <span>{{copyrightText()}}, </span>
            <a [routerLink]="'/'">{{settings.get('branding.site_name')}}</a>
        </div>
        <custom-menu position="footer" [horizontal]="true"></custom-menu>
    </div>
</footer>
